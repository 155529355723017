import { cn } from '@bem-react/classname';
import { useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import Slider from "react-slick";
import ArrowButton from './components/ArrowButton';
import ReviewCard from './components/ReviewCard';
import './ReviewsSlider.scss';



const ReviewsSlider = ({ dots=false }) => {
    const classItem = cn('ReviewsSlider');
    const settings = {
      dots: dots,
      arrows: !dots,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight: true,
      slidesToScroll: 1,
      dotsClass: classItem('Dots slick-dots'),
      nextArrow: <ArrowButton direction="next" />,
      prevArrow: <ArrowButton direction="prev" />
    };

    const intl = useIntl();

    return (
        <div className={classItem()}>
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <div className={classItem('Slider')}>
                {intl.locale === 'ru' &&
                <Slider {...settings}>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Джонни Бентон" image="/images/reviews/johny_benton.jpg" description="Business Development Advisor" company="comcast">
                            Идеальный инструмент для создания баннеров!<br></br>
                            Простой и интуитивный дизайн и пользовательский опыт. Достойный набор шаблонов. Я убежден, что как только ты добавляешь свои изображения и лого в какой-то шаблон он становится уникальным.
                        </ReviewCard>
                    </div>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Александр Ван Акен" image="/images/reviews/Alexander.jpg" description="Креативный директор<br/>Creaktor® Branding Agency" company="creaactor">
                            О боги, этот инструмент просто потрясающий!<br></br>
                            Возможность анимировать дизайн в редакторе - просто вау! Очень полезный инструмент, и мне он направится. Использую его для всех своих клиентов. Создавая один дизайн пользователь Viewst в моменте видит как он применятся ко всем выбранным форматам. Конструктор как бы имитирует работу дизайнера. Можно внести изменения в один баннер, а в остальных все автоматически обновится.
                        </ReviewCard>
                    </div>
                </Slider>
                }
                {intl.locale === 'en' &&
                <Slider {...settings}>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Johnny Benton" image="/images/reviews/johny_benton.jpg" description="Business Development Advisor" company="comcast">
                            The perfect ad creation tool!<br></br>
                            Very clean and user friendly UI. - I also like the fact that it is not overwhelming with a bunch of options in templates. I firmly believe that once anyone adds their photos and elements that any template becomes your own. There are only so many templates needed and this software is spot on.
                        </ReviewCard>
                    </div>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Alexander van Aken" image="/images/reviews/Alexander.jpg" description="Executive Creative Director<br/>Creaktor® Branding Agency" company="creaactor">
                            Holy cow, this tool is awesome!<br></br>
                            The ability to animate your design PER design! I mean, wow! Really useful and I love it. I use this for all my clients. When a user uses viewst he can quickly see that one design will be applied to all other designs. It kinda mimicks the position. Everything can be altered on 1 design and updated in the others.
                        </ReviewCard>
                    </div>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Sebastian Jaeger Filmstro" image="/images/reviews/Sebastian_Jaeger_Filmstro.jpeg" description="Founder & Musical Director<br/>Filmstro" company="Filmstro">
                            I was in a real pinch and under time pressure to deliver some banners, so, instead of waiting for a designer, I used Viewst to do them myself.<br/>
                            Super impressed with the results and saved myself thousands of dollars!
                        </ReviewCard>
                    </div>
                    {/* <div className={classItem('Slide')}>
                        <ReviewCard name="Mark V." image="/images/profile-pic@2x.png" description="Director, Marketing and Advertising">
                            This is REAL AdSense. Try Ad Common Sense...Overall: An excellent onboarding sequence that run you through the fundamentals. A simple clean UI, very focused flows, I like everything about this little gem of an app as it takes a lot of the heavy lifting when creating ads with some nice intuitive tools along the way. One of the highlights for me is that although my Canva account is structured & organized, it always feel like ads don't belong in there & should live somewhere else... At last, I've now found my new somewhere else. It's my new GoTo place for ad creation, a vast improvement on my current solution. The support team are helpful & very solution oriented as well. This is a great app, great value, great support. Highly recommended if you create your own ads!!!
                        </ReviewCard>
                    </div>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Ken Moo" image="/images/profile-pic@2x.png" description="">
                            The tool reminds me of Relaythat, but it's much better. The editor of RT is rigid and does not allow you to create graphics at the same time for different platforms. In contrast, Viewst gives you complete control over the editor. You can drag and drop the text and elements anywhere you want, like Canva. Once you're done, you can duplicate it to fit multiple platforms with 1 click. Better yet, you can export in HTML5 for use directly in GDN ads. Handy! Animating the graphic is not a must-have feature for me. But an icing on the cake. Nice!
                        </ReviewCard>
                    </div>
                    <div className={classItem('Slide')}>
                        <ReviewCard name="Belal A." image="/images/profile-pic@2x.png" description="Co-founder">
                            "Viewst is perfect for me as an entrepreneur"<br></br>
                            it is extremely easy to use, I was able to generate a twitter banner, and LinkedIn banner literally in minutes, with no design experience, I love it.
                        </ReviewCard>
                    </div> */}
                </Slider>
                }
            </div>
        </div>
    )
};

export default ReviewsSlider;
