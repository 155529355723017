import React from 'react';
import { cn } from "@bem-react/classname";

import './ArrowButton.scss';

const ArrowButton = ({ direction, style, onClick }) => {
    const classItem = cn('ArrowButton');
    return (
        <button style={style} onClick={onClick} className={classItem({ direction })}>
            { direction }
        </button>
    )
}

export default ArrowButton;