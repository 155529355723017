import { cn } from '@bem-react/classname';
import React from 'react';
import './ReviewCard.scss';


const ReviewCard = ({ name, description, image, children, company }) => {
    const classItem = cn('ReviewCard');

    return (
        <div className={classItem()}>
            <div className={classItem('Profile')}>
                <img className={classItem('ProfilePicture')}src={image} loading="lazy" alt={name} />
                <div className={classItem('ProfileContent')}>
                    <p className={classItem('ProfileTitle')}>
                        { name }
                    </p>
                    <p className={classItem('ProfileDescription')} dangerouslySetInnerHTML={{__html: description}} >
                    </p>
                    {/* <img className={classItem('ProfileCompany')} src={`./images/reviews/${company}_logo.png`} loading="lazy" alt={company} /> */}
                </div>
            </div>
            <div className={classItem('Content')}>
                { children }
            </div>
        </div>
    )
};

export default ReviewCard;
